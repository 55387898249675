import cx from "classnames"
import { Link } from "gatsby"
import React, { useState } from "react"

import darkAnonimattaLogo from "../../../../images/content/logo-dark.svg"
import lightAnonimattaLogo from "../../../../images/content/logo-light.svg"
import Icon from "../Icon"
import Image from "../Image"
import Subscription from "../Subscription"
// import Theme from "../Theme"
import styles from "./Footer.module.less"

const menu = [
  {
    title: "Entrar",
    url: "/login",
  },
  {
    title: "Assinar agora",
    url: "/planos",
  },
  {
    title: "Para Criadores",
    url: "/planos",
  },
  {
    title: "Planos",
    url: "/planos",
  },
  {
    title: "Ajuda",
    url: "/faq",
  },
  {
    title: "Termos",
    url: "/termos",
  },
  {
    title: "Privacidade",
    url: "/privacidade",
  },
]

const socials = [
  {
    title: "facebook",
    size: "16",
    url: "https://www.facebook.com/anonimatta.br",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/Anonimatta_br",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/anonimatta.com.br",
  },
]

const Footer = () => {
  const [visible, setVisible] = useState(false)

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cx("container", styles.container)}>
          <div className={styles.col}>
            <div className={styles.box}>
              <Link className={styles.logo} to="/">
                <Image
                  src={darkAnonimattaLogo}
                  srcDark={lightAnonimattaLogo}
                  alt="Anonimatta"
                />
              </Link>
              {/* <Theme className={styles.theme} /> */}
            </div>
            <div className={cx(styles.item, { [styles.active]: visible })}>
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}
              >
                outras páginas
                <Icon name="arrow-bottom" size="9" />
              </div>
              <div className={styles.menu}>
                {menu.map((x, index) => (
                  <Link
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>Suporte</div>
            <div className={styles.info}>
              <p>
                Para dúvidas, problemas técnicos ou sugestões: <br />{" "}
                <a href="mailto:suporte@anonimatta.com.br">
                  suporte@anonimatta.com.br
                </a>{" "}
              </p>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>Notificações</div>
            <div className={styles.info}>
              Cadastre seu email para receber notificações, promoções ou
              novidades.
            </div>
            <Subscription
              className={styles.subscription}
              placeholder="Digite seu email"
            />
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cx("container", styles.container)}>
          <div className={styles.copyright}>
            © Todos os direitos reservados copyright 2021 <br />
            Slide Box Apresentações LTDA - CNPJ: 27.476.350/0001-85
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
