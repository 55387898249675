import cx from "classnames"
import { Link } from "gatsby"
import React, { useState } from "react"

import darkAnonimattaLogo from "../../../../images/content/logo-dark.svg"
import lightAnonimattaLogo from "../../../../images/content/logo-light.svg"
import Icon from "../Icon"
import Image from "../Image"
import DropdownMenu from "./DropdownMenu"
import styles from "./Header.module.less"

const navLinks = [
  {
    title: "Planos",
    url: "/planos",
  },
  {
    title: "Ajuda",
    url: "/faq",
  },
]

const mobileLinks = [
  {
    title: "Entrar",
    url: "/login",
  },
  {
    title: "Assinar agora",
    url: "/cadastro-assinante",
  },
  {
    title: "Para Criadores",
    url: "/cadastro-criador",
  },
  {
    title: "Planos",
    url: "/planos",
  },
  {
    title: "Ajuda",
    url: "/faq",
  },
]

const socials = [
  {
    title: "facebook",
    size: "16",
    url: "https://www.facebook.com/anonimatta.br",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/Anonimatta_br",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/anonimatta.com.br",
  },
]

const contact = [
  {
    title: "Suporte",
    content:
      "Para dúvidas, problemas técnicos ou sugestões: suporte@anonimatta.com.br",
  },
]

const Header = () => {
  const [visibleNav, setVisibleNav] = useState(false)

  return (
    <>
      <header className={styles.header}>
        <div className={cx("container", styles.container)}>
          <Link
            className={styles.logo}
            to="/"
            onClick={() => setVisibleNav(false)}
          >
            <Image
              className={styles.pic}
              src={darkAnonimattaLogo}
              srcDark={lightAnonimattaLogo}
              alt="Anonimatta"
            />
          </Link>
          <div className={cx(styles.wrap, { [styles.active]: visibleNav })}>
            <nav className={styles.mobilelinks}>
              {mobileLinks.map((x, index) =>
                x.content ? (
                  <DropdownMenu
                    className={styles.group}
                    item={x}
                    key={index}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <Link
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                    onClick={() => setVisibleNav(false)}
                  >
                    {x.title}
                  </Link>
                )
              )}
            </nav>

            <nav className={styles.nav}>
              {navLinks.map((x, index) =>
                x.content ? (
                  <DropdownMenu
                    className={styles.group}
                    item={x}
                    key={index}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <Link
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                    onClick={() => setVisibleNav(false)}
                  >
                    {x.title}
                  </Link>
                )
              )}
            </nav>
            <div className={styles.details}>
              <div className={styles.contact}>
                {contact.map((x, index) => (
                  <div className={styles.element} key={index}>
                    <div className={styles.category}>{x.title}</div>
                    <div className={styles.text}>{x.content}</div>
                  </div>
                ))}
              </div>

              <Link
                className={cx(
                  "first-button-stroke button-small",
                  styles.button
                )}
                to="/login"
              >
                Entrar
              </Link>

              <div className={styles.socials}>
                {socials.map((x, index) => (
                  <a
                    className={styles.social}
                    href={x.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                  >
                    <Icon name={x.title} size={x.size} />
                  </a>
                ))}
              </div>

              {/* <Link
              className={cx("second-button-stroke button-small", styles.button)}
              to="/cadastro-assinante"
            >
              Cadastrar
            </Link> */}
            </div>
          </div>

          <Link
            className={cx("button-stroke", styles.loginlandingbtn)}
            to="/login"
          >
            Entrar
          </Link>

          {/* <Link
          className={cx("second-button-stroke button-small", styles.button)}
          to="/cadastro-assinante"
        >
          Cadastrar
        </Link> */}

          <button
            className={cx(styles.burger, { [styles.active]: visibleNav })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </header>
    </>
  )
}

export default Header
