import React, { useEffect } from "react"

import Footer from "../Footer"
import Header from "../Header"
import styles from "./Page.module.less"

import { Helmet } from "react-helmet"

const Page = ({ children }) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [window])

  return (
    <>
      <Helmet>
        <script src="//code-sa1.jivosite.com/widget/fN1PHwqW1A" async></script>
      </Helmet>
      <div className={styles.page}>
        <Header />
        <div className={styles.inner}>{children}</div>
        <Footer />
      </div>
    </>
  )
}

export default Page
